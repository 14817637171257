import React from "react";

import "./additionalInfo.scss";
const AdditionalInfo = () => {
  return (
    <div className="additional-container">
      {/* Your other content here */}

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 d-flex additional-col">
          <div className="text-center d-flex align-items-center">
            <img
              alt="Icon made by Freepik from www.flaticon.com"
              src="badge.png"
              className="additional-icons"
            />
            <div>
              <h6 className="text-uppercase fw-bold text-start">Top Quality</h6>
              <div className="additional-info text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 d-flex additional-col">
          <div className="text-center d-flex align-items-center">
            <img
              alt="Icon made By Irvan Kurnianto from www.flaticon.com"
              src="bar.png"
              className="additional-icons"
            />
            <div>
              <h6 className="text-uppercase fw-bold text-start">Low Cost</h6>
              <div className="additional-info text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 d-flex additional-col">
          <div className="text-center d-flex align-items-center">
            <img
              alt="Icon made by Freepik from www.flaticon.com"
              src="team.png"
              className="additional-icons"
            />
            <div>
              <h6 className="text-uppercase fw-bold text-start">Best Rates</h6>
              <div className="additional-info text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
