import Header from "../header/Header.js";
import Footer from "../footer/Footer.js";
import React from "react";
import Home from "../home/Home.js";
import AdditionalInfo from "../additionalInfo/AdditionalInfo.js";
import About from "../about/About.js";
import Services from "../services/Services.js";
import Contacts from "../contacts/Contacts.js";
const Layout = (props) => {
  return (
    <div>
      <Header />
      <Home />
      <AdditionalInfo />
      <About />
      <Services />
      <Contacts />
      <div>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
