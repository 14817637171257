import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="image-container">
        <div className="d-flex position-relative">
          <img src="sun_panels3.jpg" alt="image1" className="image" />
          <img src="icr-electro.jpg" alt="image2" className="image second" />
          <img src="cabel_installation.jpg" alt="image3" className="image" />
        </div>
        <div className="overlay"></div>
        <div className="content">
          <div className="text-center mb-5">
            <img src="color_logo2.svg" alt="logo" className="logo-footer" />
          </div>
          <small className="footer-span">
            © 2022 Методиев и синове - Електропулс ЕООД
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
