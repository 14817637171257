import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.scss";
import { Link } from "react-scroll";

const Header = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  const closeNavbar = () => {
    const navbarNav = document.getElementById("navbarNav");
    if (navbarNav && navbarNav.classList.contains("show")) {
      navbarNav.classList.remove("show");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTabletNow = window.innerWidth <= 768;
      setIsMobileOrTablet(isMobileOrTabletNow);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="fixed-top">
      {!isMobileOrTablet && (
        <div className="header-line">
          <span className="ms-3 me-5">
            <a href={`mailto:melectropulse@gmail.com`}>
              <FontAwesomeIcon icon="envelope" className="me-2" />
              melectropulse@gmail.com
            </a>
          </span>
          <span>
            <a href={`href="tel:+359896759390"`}>
              <FontAwesomeIcon icon="fa-solid fa-phone" className="me-2" />
              +359896759390
            </a>
          </span>
          <span className="ms-auto">
            <span className="nav-link active">EN / BG</span>
          </span>
        </div>
      )}
      <nav className="navbar navbar-expand-lg bg-light header-container">
        <div className="container-fluid p-0">
          <div className="d-flex">
            <span className="navbar-brand">
              <img
                src="color_logo2.svg"
                className="img-logo"
                style={{ display: "flex", alignItems: "start" }}
                alt="electropulse-logo"
              />
            </span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  to="home"
                  smooth={true}
                  duration={500}
                  offset={isMobileOrTablet ? -70 : -120}
                  onClick={closeNavbar}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  to="about"
                  smooth={true}
                  duration={500}
                  offset={isMobileOrTablet ? -70 : -120}
                  onClick={closeNavbar}
                >
                  About
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  to="services"
                  smooth={true}
                  duration={500}
                  offset={isMobileOrTablet ? -70 : -120}
                  onClick={closeNavbar}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link contact-button"
                  to="contacts"
                  smooth={true}
                  duration={500}
                  offset={isMobileOrTablet ? -70 : -120}
                  onClick={closeNavbar}
                >
                  Contacts
                </Link>
              </li>
            </ul>

            {isMobileOrTablet && (
              <div className="text-center mt-3">
                <hr />
                <div className="fw-bold">Select Language:</div>
                <span className="ms-auto">
                  <span>EN / BG</span>
                </span>
                <hr />
                <div className="fw-bold mt-3">Contacts:</div>
                <div className="mb-2">
                  <a href={`mailto:melectropulse@gmail.com`}>
                    <FontAwesomeIcon icon="envelope" className="me-2" />
                    melectropulse@gmail.com
                  </a>
                </div>
                <div className="pb-3">
                  <a href={`href="tel:+359896759390"`}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-phone"
                      className="me-2"
                    />
                    +359896759390
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
