import logo from "./logo.svg";
import "./styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Layout from "./components/layout/Layout";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faPhone,
  faBuilding,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

library.add(faEnvelope, faPhone, faBuilding, faClock);
function App() {
  return <Layout />;
}

export default App;
