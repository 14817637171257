import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./contacts.scss";

const Contacts = () => {
  const [inputs, setInputs] = useState({ name: "", email: "", message: "" });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    const label = event.currentTarget.parentElement;
    if (label && event.target.value !== "") {
      label.classList.remove("active");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(inputs)); // Displaying the input values for testing
  };

  const handleFocus = (e) => {
    const label = e.currentTarget.parentElement;
    console.log(e.target.value);
    if (label && e.target.value === "") {
      label.classList.add("active");
    }
  };

  const handleBlur = (e) => {
    const label = e.currentTarget.parentElement;
    if (label && e.target.value === "") {
      label.classList.remove("active");
    }
  };

  return (
    <div className="contacts-container" id="contacts">
      <div className="contacts-card">
        <div className="row">
          <div className="col-md-7 col-sm-12 col-xs-12 p-5">
            <div className="contacts-first-col">
              <h1 className="fw-bold text-uppercase">
                <span className="orange-color">Contact</span> US
              </h1>
              <div className="my-3">
                Feel free to contact us anytime. We will get back to you as soon
                as we can!
              </div>

              <form onSubmit={handleSubmit} className="d-grid">
                <label className={`input-label ${inputs.name ? "active" : ""}`}>
                  <input
                    type="text"
                    name="name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {inputs.name.length === 0 && <span>Your Name</span>}
                </label>
                <label
                  className={`input-label ${inputs.email ? "active" : ""}`}
                >
                  <input
                    type="email"
                    name="email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {inputs.email.length === 0 && <span>Your Email</span>}
                </label>
                <label
                  className={`input-label ${inputs.message ? "active" : ""}`}
                >
                  <input
                    name="message"
                    value={inputs.message || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {inputs.message.length === 0 && <span>Your Message</span>}
                </label>
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 col-xs-12 d-flex align-items-center">
            <div className="info-card">
              <div className="orange-box"></div>
              <h2>Information</h2>
              <div>
                <div className="info-components">
                  <a href={`mailto:melectropulse@gmail.com`}>
                    <FontAwesomeIcon icon="envelope" />
                    <span>melectropulse@gmail.com</span>
                  </a>
                </div>
              </div>
              <div>
                <div className="info-components">
                  <a href={`href="tel:+359896759390"`}>
                    <FontAwesomeIcon icon="fa-solid fa-phone" />
                    <span>+359896759390</span>
                  </a>
                </div>
              </div>
              <div>
                <div className="info-components">
                  <FontAwesomeIcon icon="building" />
                  <span> bul. Svoboda 3, Plovdiv</span>
                </div>
              </div>
              <div>
                <div className="info-components">
                  <FontAwesomeIcon icon="clock" />
                  <span>08:00 - 18:00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
