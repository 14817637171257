import React, { useState, useEffect } from "react";
import "./about.scss";

const About = () => {
  const [activeCard, setActiveCard] = useState(2);

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTabletNow = window.innerWidth <= 768; // You can adjust this threshold as needed
      setIsMobileOrTablet(isMobileOrTabletNow);
      if (isMobileOrTablet) {
        setActiveCard(1);
      }
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileOrTablet]);

  const handleCardClick = (cardNumber) => {
    setActiveCard(cardNumber === activeCard ? 2 : cardNumber);
  };

  const getCardOrder = (cardNumber) => {
    const centerIndex = Math.floor(cards.length / 2);
    const distance = cardNumber - activeCard;
    return centerIndex + distance;
  };

  const cards = [
    {
      id: 1,
      imgSrc: "history-book.png",
      imgAlt: "Icon made by Freepik from www.flaticon.com",
      header: "Our Story",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ",
    },
    {
      id: 2,
      imgSrc: "electrician.png",
      imgAlt: "Icon made By Uniconlabs from www.flaticon.com",
      header: "About Us",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
    },
    {
      id: 3,
      imgSrc: "transmission-tower.png",
      imgAlt: "Icon made by Freepik from www.flaticon.com",
      header: "Projects",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
    },
  ];

  return (
    <div className="about container text-center" id="about">
      <h1 className="fw-bold">
        About <span className="orange-color">M & S Electropuse</span>{" "}
      </h1>
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      </span>
      <br />
      <span>tempor incididunt ut labore et dolore magna aliqua.</span>
      <div className="card-container">
        {cards.map((card) => (
          <div
            key={card.id}
            className={`about-card ${activeCard === card.id ? "active" : ""}`}
            style={{ order: getCardOrder(card.id) }}
            onClick={() => handleCardClick(card.id)}
          >
            <h2>{card.header}</h2>
            <p>{card.description}</p>

            <p className="fw-bold">Read More</p>
            <img src={card.imgSrc} alt={card.imgAlt} className={"card-image"} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
