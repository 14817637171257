import React from "react";
import "./home.scss";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div className="home-container" id="home">
      {/* Your other content here */}

      <div className="home-background-image">
        <div className="text-content">
          <h6 className="mb-0">SINCE 1953</h6>
          <h1 className="text-uppercase fw-bold">We build the trust</h1>

          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod
          </span>
          <span>tempor incididunt ut labore et dolore magna aliqua.</span>

          <div className="mt-5">
            <Link
              className=" contact-button"
              to="contacts"
              smooth={true}
              duration={500}
              offset={-120}
            >
              Contacts
            </Link>
          </div>
        </div>
      </div>
      <img src="tool.png" className="tool-class" />
    </div>
  );
};

export default Home;
