import React, { useState, useEffect } from "react";
import "./services.scss";

const Services = () => {
  const [activeCard, SetActiveCard] = useState(0);

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTabletNow = window.innerWidth <= 768; // You can adjust this threshold as needed
      setIsMobileOrTablet(isMobileOrTabletNow);
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cards = [
    {
      id: 0,
      imageURL: "pexels-anamul-rezwan-1216544.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. 0",
      name: "First Service",
    },
    {
      id: 1,
      imageURL: "pexels-los-muertos-crew-8853499.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. 1",
      name: "Second Service",
    },
    {
      id: 2,
      imageURL: "pexels-sami-abdullah-7359568.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. 2",
      name: "Third Service",
    },
    {
      id: 3,
      imageURL: "fourth_service.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. 3",
      name: "Fourth Service",
    },
  ];
  return isMobileOrTablet ? (
    <div className="services-container" id="services">
      <h1 className="fw-bold text-uppercase">
        Our <span className="orange-color">Services</span>{" "}
      </h1>

      {cards.map((item) => {
        return (
          <div
            className={`project-card ${
              item.id === activeCard ? "active-card" : ""
            }`}
            key={item.id}
            style={{
              backgroundImage: `url(${item.imageURL})`,
            }}
            onClick={() => SetActiveCard(item.id)}
          >
            <div className="card">
              <div className="card-title">{item.name}</div>
              <p>{item.description}</p>
              <div className="read-more-btn">Read more</div>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="services-container" id="services">
      <div className="row w-100">
        <div className="col-xs-12 col-sm-12 col-md-3 position-relative overflow-hidden">
          <img
            src={`${cards[activeCard]?.imageURL}`}
            alt="bg-services"
            width={"100%"}
            className="bg-services-image"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9 inner-services-container">
          <div className="row mb-5">
            <div className="col-12">
              <h1 className="fw-bold text-uppercase">
                Our <span className="orange-color">Services</span>{" "}
              </h1>
              <div className="w-75">{cards[activeCard]?.description}</div>
            </div>
          </div>
          <div className="row">
            {cards.map((item) => {
              return (
                <div
                  className={`col-xs-12 col-sm-12 col-md-3 p-0 position-relative services-card ${
                    item.id === activeCard ? "active-card" : ""
                  }`}
                  key={item.id}
                  onClick={() => SetActiveCard(item.id)}
                >
                  {item.id === activeCard && (
                    <div className="services-details">Details</div>
                  )}
                  <img
                    src={`${item.imageURL}`}
                    alt="electrician"
                    width={"100%"}
                    className="services-image"
                  />
                  <div className="text-center text-uppercase ms-5">
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
